export interface FormElement {
  type: 'h1' | 'input' | 'button' | 'select' | 'hr' | 'textarea';
  attributes?: { [key: string]: string };
  content?: string;
  options?: { value: string; text: string; selected?: boolean }[];
}

export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement: true,
  formAttributes?: { [key: string]: string },
): HTMLElement;
export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement?: false,
  formAttributes?: { [key: string]: string },
): string;

/**
 * Generates HTML for a form based on the provided elements and attributes.
 *
 * @param elements - An array of `FormElement` objects that define the form's elements.
 * @param returnAsElement - A boolean indicating whether to return the form as an `HTMLElement` (default is `false`).
 * @param formAttributes - An optional object containing key-value pairs for form attributes.
 * @returns The generated form as an HTML string or an `HTMLElement` based on the `returnAsElement` parameter.
 */
export default function generateFormHtml(
  elements: FormElement[],
  returnAsElement: boolean = false,
  formAttributes?: { [key: string]: string },
): string | HTMLElement {
  const htmlElements = elements
    .map((element) => {
      const attrs = element.attributes
        ? Object.entries(element.attributes)
            .map(([key, value]) => (key !== 'label' ? `${key}="${value}"` : ''))
            .filter((attr) => attr.length)
            .join(' ')
        : '';
      const content = element.content || '';

      const label = element.attributes?.label
        ? `<label for="${element.attributes.id}">${element.attributes.label}</label>`
        : '';

      if (element.type === 'input') {
        return `${label}<input${attrs.length ? ' ' + attrs : ''} />`;
      }

      if (element.type === 'select') {
        const options = element.options
          ? element.options
              .map(
                (option) =>
                  `<option value="${option.value}"${option.selected ? ' selected' : ''}>${option.text}</option>`,
              )
              .join('')
          : '';
        return `${label}<select ${attrs}>${options}</select>`;
      }

      if (element.type === 'textarea') {
        return `${label}<textarea${attrs.length ? ' ' + attrs : ''}></textarea>`;
      }

      return `<${element.type}${attrs.length ? ' ' + attrs : ''}>${content}</${element.type}>`;
    })
    .join('');

  const formAttrs = formAttributes
    ? Object.entries(formAttributes)
        .map(([key, value]) => `${key}="${value}"`)
        .join(' ')
    : '';

  const formHtml = `<form${formAttrs.length ? ' ' + formAttrs : ''}>${htmlElements}</form>`;

  if (returnAsElement) {
    const template = document.createElement('template');
    template.innerHTML = formHtml.trim();
    return template.content.firstChild as HTMLElement;
  } else {
    return formHtml;
  }
}
