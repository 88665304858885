import { Chart } from '@/classes/Chart';
import toBinaryStr from './toBinaryStr';

/**
 * Converts a Chart object to a base64-encoded string representation.
 *
 * The resulting string includes the chart's name, start and end dates,
 * and a list of developers with their respective tasks and PTOs.
 * Each task includes its name, link, start and end dates, and status.
 *
 * @param {Chart} chart - The Chart object to convert.
 * @returns {string} - The base64-encoded string representation of the chart.
 */
export default function convertChartToString(chart: Chart): string {
  const developers = chart.getDevelopers();

  return btoa(
    toBinaryStr(
      JSON.stringify({
        timestamp: chart.getTimestamp(),
        name: chart.getName(),
        start: chart.getStart(),
        end: chart.getEnd(),
        developers: developers.map((developer) => ({
          name: developer.getName(),
          tasks: developer.getTasks().map((task) => ({
            name: task.getName(),
            link: task.getLink(),
            start: task.getStart(),
            end: task.getEnd(),
            status: task.getStatus(),
          })),
          ptos: developer.getPTOs(),
        })),
      }),
    ),
  );
}
